
.main-body {
    padding: 15px;
}
.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}
.course-card{
    padding: 16px;
    background: rgb(250 251 255);
    box-shadow: 0px 0px 3px rgb(0 0 0 / 62%);
    border-radius: 6px;
    margin-bottom: 30px;
}
.nav.nav-pills .nav-link.active {
    background-color: #34cad2;
    border-radius: 2px;
    color: #fff;
    font-weight: 500;
}
.btn-primary {
    background-color: #26c9d3;
    border-color: #26c9d3;
    border-radius: 2px !important;
}

.course-image{
    /* height: 240px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    border-radius: 4px;
    width: 100%;
    max-height: 300px;
    height: 300px;
  
}
.course-card_type{
height: 20px;

font-size: 16px;
line-height: 24px;
color: #004f8a;
}
.course-name{
    color: #000;
font-weight: 600;
font-size: 20px;
line-height: 28px;
margin-top: 23px;
}
.instructor-name{
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
}
.course-one__single .course-one__image1 img {
   display: block;
    width: 100%;
    max-height: 300px;
    object-fit: cover;

  }
.lecture-count{
    font-size: 16px;
    padding-top: 5px;
    /* color: #176779; */

}
.btn-class {
    width: 160px;
    height: 40px;
    border-radius: 4px;
}
.btn-pink {
    background-color: #34cad2;
}
.btn {
    outline: none;
    cursor: pointer;
    color: #fff;
    line-height: 1.5;
    padding: 7px;
    min-width: 90px;
    box-shadow: 0 1px 3px rgba(0,0,0,.25);
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all .3s;
}
.progress-line{
    background-color: #f4efef;
padding: 12px 16px;
border-radius: 4px;
margin-top: 7px;
}
.progress__text {
    /* font-family: "Montserrat"; */
    font-size: 16px;
}
.progress {
    background-color: #c4c4c4;
    height: 7px;
}
.progress-bar {
    background-color: #34cad2;
}
.course-desc{
    width: 95%;
overflow: hidden;
text-overflow: ellipsis;
max-width: 100%;
}
.search-form--light .btn1{
    position: absolute;
right: 20px;
background-color: transparent;
border: 1px solid transparent;
}
.search-form--light {
    display: flex;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}
.user-image-c {
    width: 95px;
}
.user-name-c {
    float: left;
    /* margin-top: 15px; */
}
.user-name-c a{
    color: #000;
    /* margin-top: 15px; */
}
.user-name-c {
    width: calc(100% - 60px);
    /* margin-left: -45px; */
    text-transform: capitalize;
    margin-top: 20px;
}
.stars-rev-c {
    font-size: 15px;
    color: #ecc118;
    /* margin-left: -40%; */
    margin-top: -6px;
}
.stars-rev-c data {
    font-size: 12px;
    color: #666;
    float: right;
}
.modal-title-c {
    margin-bottom: 0;
    line-height: 1.5;
}
.h4, h4 {
    font-size: 1.5rem;
}
.hr-sep{
    width: 90%;
margin-top: 25px;
}
.nav-tabs .nav-link {
    margin-bottom: -2px;
}
.btn-outline-info{
    border-width: 1px;
    border-style: solid;
}
.border-radius-3 {
    border-radius: 3px;
}
@media screen and (max-width:767px) {
    .course-card{margin-bottom: 15px;}
}
.boleto_btn {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.boleto_btn button.btn {
    padding: 11px 35px;
    box-shadow: 2px 3px 7px #000000e8;
    transition: 0.3s all;
}
.boleto_btn button.btn:hover{box-shadow: none}

.btn-success{    background-color: #1bbc40 !important;
    border-color: #1eb03f !important;}
.default_img {
    max-width: 500px;
    display: block;
    margin: 10% auto 0;
    text-align: center;
}
.default_img img {
    opacity: 0.4;
}
.default_img h5 {
    font-weight: 600;
    color: #939494;
}