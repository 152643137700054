.course-details {
    padding: 80px 0;
    position: relative;
}
section.course-details::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-color: #242929;
    height: 300px;
    width: 100%;
}
.course-details-instrutores {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #81868a;
    font-size: 18px;
    font-weight: 500;
}
.course-details-instrutores img {
    width: 53px;
    border-radius: 50%;
    margin-right: 20px;
}
.course-details-instrutores a {
    color: #34cad2;
    margin-left: 10px;
}
.course-name-other {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}
.course-name-detail {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    margin-bottom: 5px;
}
.course-one__stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.course-one__stars-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.course-details .course-one__category {
    position: relative;
    top: auto;
    left: auto;
    -webkit-transform: translate(0);
    transform: translate(0);
}
.course-one__category {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    letter-spacing: .2em;
    background-color: #34cad2;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 27px;
    padding: 0 17px;
    position: absolute;
    left: 10px;
    top: 20px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.full-course-price {
    background-color: #f1f1f1;
    text-align: center;
    padding: 50px;
}
.full-course-price-text {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #81868a;
    text-transform: uppercase;
    letter-spacing: .2em;
    line-height: 1em;
}
.full-course-price-amount {
    margin: 0;
    color: #012237;
    font-size: 30px;
    font-weight: 600;
    line-height: 1em;
    margin-top: 10px;
}
.full-course-price-btn {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    width: 100%;
    margin-top: 30px;
   
}
.course-details__meta {
    margin-top: 30px;
    margin-bottom: 30px;
    border-style: solid;
    border-width: 2px;
    border-color: #f1f1f1;
}
.more-course-details-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #81868a;
    font-size: 18px;
    font-weight: 500;
    padding: 25px 50px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
@media only screen and (max-width: 767px) {
    .more-course-details-link{
        padding: 15px 5px;
    }
    .course-details__list{padding: 15px !important;}
    .course-details__list-title{margin-bottom: 10px !important;}
    .course-details__list-item + .course-details__list-item{ padding-top: 10px !important;margin-top: 10px !important;}
}
.more-course-details-link span {
    color: #012237;
    margin-left: 15px;
}
.course-details__meta-icon {
    width: 40px;
    height: 40px;
    background-color: #34cad2;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
}
.course-details__meta-icon i {
    color: #fff;
    font-size: 16px;
}
.more-course-details-link:nth-child(2) .course-details__meta-icon {
    background-color: #7c4bc0;
}
.more-course-details-link:nth-child(3) .course-details__meta-icon {
    background-color: #f16101;
}
.more-course-details-link:nth-child(4) .course-details__meta-icon {
    background-color: #34cad2;
}
.more-course-details-link:nth-child(5) .course-details__meta-icon {
    background-color: #d23f3f;
}
.more-course-details-link:nth-child(5) .course-details__meta-icon::before {
    border-color: #d23f3f;
}
.course-details__meta-icon:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid;
    border-color: #34cad2;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.more-course-details-link:nth-child(2) .course-details__meta-icon::before {
    border-color: #7c4bc0;
}
.more-course-details-link:nth-child(3) .course-details__meta-icon::before {
    border-color: #f16101;
}
.more-course-details-link + .more-course-details-link {
    border-top: 2px solid #f1f1f1;
}
.course-details__list {
    border-style: solid;
    border-width: 2px;
    border-color: #f1f1f1;
    padding: 50px;
}
.course-details__list-title {
    margin: 0;
    color: #012237;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}
.course-details__list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; */
}
.course-details__list-img {
    width: 77px;
    height: 64px;
    margin-right: 20px;
}
.course-details__list-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.course-details__list-author {
    font-size: 14px;
    color: #81868a;
    line-height: 1em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.course-details__list-author span {
    color: #34cad2;
    margin-left: 6px;
}
.course-details__list h3 {
    font-size: 16px;
    font-weight: 500;
    color: #222;
    margin: 0;
    line-height: 1em;
    margin-top: 5px;
}
.course-details__list h3 a {
    color: inherit;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.course-details__list-stars {
    display: inline-block;
}
.course-details__list-stars span {
    padding-left: 6px;
}
.course-details__list-stars i {
    font-size: 12px;
    color: #f16101;
}
.course-details__list-item + .course-details__list-item {
    border-top: 2px solid #f1f1f1;
    padding-top: 30px;
    margin-top: 30px;
}
.course-details__tab-navs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f1f1f1;
    margin-top: 40px;
    margin-bottom: 0px;
}
.course-details__tab-navs li {
    line-height: 1em;
}
.course-details__tab-navs  a:hover, .course-details__tab-navs li a.active {
    background-color: #34cad2;
    color: #fff;
}
.course-details__tab-navs  a {
    color: #81868a;
    font-size: 18px;
    font-weight: 500;
    display: block;
    padding: 20px 50px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
}
  .nav.nav-pills .nav-link.active {
    /* background-color:#34cad2; */
}
.course-details__tab-navs  a:hover:before, .course-details__tab-navs  a.active:before {
    -webkit-transform: translateX(-50%) scaleY(1);
    transform: translateX(-50%) scaleY(1);
}
.course-details__tab-navs ::before {
    content: '';
    width: 35px;
    height: 10px;
    background-color: #34cad2;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(0);
    transform: translateX(-50%) scaleY(0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
}
.course-details__tab-content {
    border-style: solid;
    border-width: 2px;
    border-color: #f1f1f1;
    padding: 50px;
}

.course-details__progress-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.course-details__progress-text {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    min-width: 100px;
}
.course-details__progress-bar {
    position: relative;
    width: 210px;
    height: 2px;
    background-color: #f1f1f1;
    margin-left: 20px;
    margin-right: 20px;
}
.course-details__progress-bar span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #f16101;
}
.course-details__progress-bar span:before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: #f16101;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.course-details__progress-count {
    margin: 0;
    font-family: "Satisfy";
}
.course-details__review-box {
    width: 237px;
    height: 237px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #f1f1f1;
}
.course-details__review-count {
    margin: 0;
    line-height: 1em;
    font-size: 60px;
    color: #34cad2;
    margin-bottom: 5px;
}
.course-details__review-stars {
    font-size: 24px;
    line-height: 1em;
    margin-bottom: 15px;
}
.course-details__review-stars i {
    color: #f16101;
}


.course-details__comment {
    margin-left: -50px;
    margin-right: -50px;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    padding-top: 50px;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 40px;
    margin-top: 50px;
}
.course-details__comment-top, .course-details__comment-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.course-details__comment-img {
    width: 76px;
    margin-right: 20px;
}
.course-details__comment-img > img {
    width: 100%;
}
.course-details__comment-name {
    font-size: 20px;
    font-weight: 600;
    color: #012237;
    margin: 0;
}
.course-details__comment-meta {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.course-details__comment-date {
    font-size: 16px;
    color: #34cad2;
    margin: 0;
    font-weight: 500;
    margin-right: 20px;
}
.course-details__comment-stars {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.course-details__comment-stars i {
    color: #f16101;
}

.course-details-rating {
    font-size: 18px;
    color: #81868a;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
}
.course-details-rating a {
    font-size: 20px;
    color: #f1f1f1;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.course-details__comment-form input, .course-details__comment-form textarea {
    display: block;
    width: 100%;
    height: 77px;
    background-color: #f1f1f1;
    color: #81868a;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    padding-left: 40px;
    margin-bottom: 10px;
}
.course-details__comment-form textarea {
    height: 210px;
}
.more-course-details-link:hover .course-details__meta-icon::before {
    left: 0;
}
.course-details .list-unstyled {
    margin-bottom: 0;
}
.course-details__curriculum-list li:first-child {
    padding-top: 0;
}
.course-details__curriculum-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 22.5px;
    padding-bottom: 22.5px;
    border-bottom: 1px solid #f1f1f1;
}
.course-details__curriculum-list-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.course-details__tab-content .course-details__meta-icon.video-icon {
    background-color: #7c4bc0;
}
.course-details__curriculum-list-left a {
    font-size: 16px;
    font-weight: 600;
    color: #012237;
}
.course-details-report-list-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.course-details-report-list-left span {
    font-size: 12px;
    color: #81868a;
    font-weight: 500;
    background-color: #f1f1f1;
    display: inline-block;
    vertical-align: middle;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
}
.course-details__tab-content .course-details__meta-icon.video-icon:before {
    border-color: #7c4bc0;
}
.course-details__tab-content .course-details__meta-icon:before {
    border-width: 1px;
}
.course-detail-data .tab-content {
    padding: 15px;
    border: 1px solid #eee;
    background-color: #fbfbfb;
}
.course-details__meta-icon:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid;
    border-color: #34cad2;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.course-details__curriculum-list-left span {
    font-size: 12px;
    color: #81868a;
    font-weight: 500;
    background-color: #f1f1f1;
    display: inline-block;
    vertical-align: middle;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
}

@media screen and (max-width:767px){
    .course-details{padding: 60px 0 80px;}
    .course-name-other{display: block;}
    .course-name-detail{font-size: 30px;}
    .course-one__stars-wrap i{font-size: 12px;}
    .course-details__tab-navs a{padding: 5px 15px;font-size: 16px;}
    .course-details__tab-navs{margin-top: 20px;}
    .course-name-detail{font-size: 28px;}
    .course-details-rating{font-size: 15px;}
}
@media screen and (min-width: 767px) and (max-width: 1100px) { 
    .more-course-details-link{
        padding: 25px 20px;
        font-size: 15px;
    }
    .full-course-price{padding: 30px;}
    .course-details__list{padding: 15px;}
    .course-details__list-title{margin-bottom: 20px;}
    .course-details__list-img{width: 85px;}
}