.main-container-hosted-login {
    min-height: 60vh;
}

@media screen and (min-width: 1300px) {
    .container {
        max-width: 1280px;
    }
}

@media screen and (min-width: 1400px) {
    .container {
        max-width: 1380px;
    }
}

@media screen and (min-width: 1500px) {
    .container {
        max-width: 1440px;
    }
}

@media screen and (min-width: 1600px) {
    .container {
        max-width: 1540px;
    }
}

.course-title {
    font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
    font-size: 30px;
    font-weight: 500;
    margin-top: 30px;
}

.course-nav-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 20px;
}

.class-content {
    font-size: 16px;
    font-weight: 500;
}

.class-content-detail {
    font-size: 14px;
    font-weight: 300;
}

.content-drawer {
    margin-bottom: 20px;
}

.hosted-table,
.rating-exam-selected {
    border: 1px solid #ddd;
    padding: 8px 10px;
    box-sizing: border-box;
    margin-top: -2px;
    cursor: pointer;
    transition: ease;
    border-radius: 8px;

}

.hosted-table {
    background-color: #f7f7f7;
}

.card-toggle {
    background-color: rgba(255, 255, 255, 0.03);
    border-bottom: 0px rgba(255, 255, 255, 0.125);
}

.class-subcontent-materi {
    font-size: 18px;
    font-weight: 400;
}

.content-drawer h2,
.content-drawer h3,
.content-drawer h4,
.content-drawer p,
.content-drawer span {
    font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
}

.hosted-table-subcontent-selected {
    border-left: 3px solid #26c9d3;
}

.hosted-table-subcontent,
.hosted-table-subcontent-selected {
    padding: 8px 15px 4px;
    box-sizing: border-box;
    cursor: pointer;
    transition: ease;
    border-top: 1px solid #ddd;
}

.ReactCollapse--collapse {
    transition: height .5s;
}

.class-subcontent-list {
    font-size: 18px;
    /* max-width: 70%; */
    font-weight: 600;
    line-height: 20px;
}

.icon_topic i {
    font-size: 25px;
}

.check-css {
    line-height: normal;
}

.class-list {
    font-size: 14px;
    line-height: normal;
}

.first_icon i {
    font-size: 24px;
}

.star-css {
    background-color: #f9f9f9;
    padding: 2px 5px;
    border-radius: 4px;
    margin: 2px 0px;
    font-size: 13px;
    display: inline-block;
}

.star-css i {
    color: #666666;
}

.class-list i {
    font-size: 18px;
    color: #ecc118
}

.course-video {
    margin-top: 30px;
    min-height: 440px;
    /* background-color: #000; */
}

.person__main-title {
    font-weight: 500;
    font-size: 24px;
    /* line-height: 20px; */
}

.person {
    padding: 20px 24px 36px;
    margin: 20px 0;
}

.person__wrap {
    padding-left: 17px;
    margin-bottom: 15px;
}




.content {
    /* width: 420px; */
    width: 100%;
    /* margin-top: 100px */
}

.user_review_consumption {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.ratings {
    background-color: #fff;
    padding: 50px 20px 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 10px #E0E0E0;
    /* margin-top: -25%; */
    margin-bottom: 10%;
    border-radius: 4px;
}

.product-rating {
    font-size: 20px
}

/* .stars i {
    font-size: 25px;
    color: #ecc118
} */

.rating-text {
    margin-bottom: 10px;
    border-bottom: #d1dbdb;
    border-bottom-style: solid;
    border-bottom-width: .5px;
}

.user-image {
    width: 65px;
    height: 45px;
}

.user-name {
    float: left;
    /* margin-top: 15px; */
}

/* .user-name {
    width: calc(100% - 60px);
    margin-left: -45px;
    text-transform: capitalize;
} */
.stars-rev {
    font-size: 15px;
    color: #ecc118;
    /* margin-left: -40%; */
    margin-top: -6px;
}

.stars-rev data {
    font-size: 12px;
    color: #666;
    float: right;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.h4,
h4 {
    font-size: 1.5rem;
}

.select-help-improve {
    border: 1px solid #ddd;
    padding: 10px;
    margin: .5rem 0;
    border-radius: 5px;
}

.checkbox-rate {
    margin-top: 10px;
}

.desc-rect {
    /* margin-top: 50px; */
    padding: 18px;
    background: #e7f0ff;
    border-radius: 2px;
}

.desc-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #198fe8;
    margin-top: .5rem;
    letter-spacing: 1px;
}

.desc-content {
    font-style: normal;
    font-size: 15px;
    /* text-align: justify; */
    color: #222;
    margin-top: 1rem;
}

.ques-part {
    padding: 15px;
}

.ans-sub-head {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #444;
}

.search-box {
    width: 40%;
    height: 63px;
    margin-top: 15px;
    background: #e8e8e8;
    border: 1px solid #47586d;
    box-sizing: border-box;
    border-radius: 40px;
    position: relative;
}

.search-css .form-control {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    height: 60px;
    border-radius: 50px;
    margin-left: 0;
    margin-top: 0;
    padding-left: 25px;
    border: 0;
    background-color: transparent;
}

.search-button {
    position: absolute;
    right: 5px;
    top: 2px;
    z-index: 99;
}

.ques-image {
    width: 40px;
    height: 40px;
    margin-top: 8px;
}

.name-css {
    font-size: 17px;
    color: #0863b9;
    margin-top: 4px;
    margin-bottom: 3px;
    font-weight: 500;
    text-transform: capitalize;

}

.ques-date {
    font-size: 11px;
    line-height: 15px;
    color: #666;
    font-weight: 100;
    margin-top: -7px;
}

/* .ques-box {
    padding: 7px 5px 20px 55px;
    border-bottom: 1px double #eee;
} */
.ques-box-head {
    color: #222;
    font-weight: 400;
    line-height: normal;
    font-size: 15px;
    margin-top: 5px;
}

.ques-css {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px 10px 7px 15px;
    margin: 5px;
    min-height: 200px;
    max-height: 200px;
}

.sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.default_img {
    max-width: 500px;
    display: block;
    margin: 10% auto 0;
    text-align: center;
}

.default_img img {
    opacity: 0.4;
}

.default_img h5 {
    font-weight: 600;
    color: #939494;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-button .btn {
    background-color: #2c3749;
    border-color: #2c3749;
    height: 45px;
    border-radius: 35px !important;
    /* width: 130px; */
    text-align: center;
    margin-top: 5px;
    color: #fff;
}

.avg-rate {
    padding-bottom: 15px;
    margin-top: -25px;
    font-size: 15px;
    text-transform: capitalize;
}

.give_rate .rate-select {
    color: #ffc107;
}

/* .custom_pdf_page_row canvas{
    max-width: 100%;
    margin: auto;
    }
.course-css iframe{
        max-width:100%;
      } */
.review-star .icofont-star.text-warning {
    font-size: 25px;
    /* color: #c7c7c7; */
}

.review-star .icofont-star.rate-select {
    font-size: 25px;
    /* color: #c7c7c7; */
}

.give_rate .rate-select {
    color: #525aa3;
}

.give_rate {
    color: #c7c7c7;
}

@media screen and (max-width:767px) {
    .stars-rev data {
        display: block;
        text-align: left;
        float: none;
    }

    .stars-rev {
        text-align: left
    }

    .desc-content {
        text-align: left;
    }
}

.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
}
.allow-css{
    filter: blur(1.7px);
}