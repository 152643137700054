
.main-body {
    padding: 15px;
}
.card1 {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card1 {
    position: relative;
    /* z-index: 1; */
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}

.main-body {
    padding: 15px;
}

.nav-link {
    color: #4a5568;
}
.card1 {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 48%), 0 1px 2px 0 rgb(0 0 0 / 43%);
}

.card1 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}
.image-upload>input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.image-upload {
    position: relative;
    width: 45px;
    margin-top: -24px;
    height: 45px;
    background-color: #34cad2;
    padding: 2px 7px;
    border-radius: 50%;
    border: 2px solid #007bff38;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}
.btn-live_event{
    /* border-radius: 32px; */
    background-color: #e42f2f;
    border: 4px solid #e1f3ff6b;
}
.badge_count_event {
    display: inline-block;
    float: right;
    width: 30px;
    height: 30px;
    background-color: #1bccd7;
    text-align: center;
    font-size: 14px;
    border-radius: 50%;
    color: #fff;
    padding-top: 0px;
  }
  .avatar {
    flex-shrink: 0;
    font-size: 1rem;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    position: relative;
    z-index: 0;
}
.avatar-course-img {
    width: 100%;
}