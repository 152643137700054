.page-sidebar {
    background: #34cad2;
    border-radius: 5px;
    padding: 27px 20px 30px;
    max-width: 300px;
    width: 100%;
    margin-left: -15px;
}
body{
    font-size: 16px;
    color: #000;
    margin: 0px !important;
    
}
.page-sidebar-content .widget-title {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #fff;
}
.page-sidebar-content .widget {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.page-sidebar-content .widget .term-item {
    margin: 14px 0 0;
    font-size: 16px;
    position: relative;
    color: #3c3636;
}
.page-sidebar-content .widget .term-item .count {
    position: absolute;
    right: 15px;
    top: 0px;
    font-size: 13px;
    color: #0d233b;
}
.sidebar-filter .page-sidebar-content .widget + .widget {
    margin-top: 20px;
    padding: 27px 0px;
    border-top: 1px solid #d2d2d2;
}
.main-course-list {
    /* background-color: #2c374921; */
    padding: 13px 5px 14px;
    border-radius: 5px;
}
.grid-item {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1;
    -webkit-transition: opacity 1s linear,-webkit-transform 1s ease-in-out;
    transition: opacity 1s linear,-webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out,opacity 1s linear;
    transition: transform 1s ease-in-out,opacity 1s linear,-webkit-transform 1s ease-in-out;
}
.grid-item .course-loop-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    height: 100%;
    border-radius: 5px;
    background: var(--edumall-color-box-white-background);
    box-shadow: 0 0 10px rgba(51,51,51,.15);
}
.tutor-course-header {
    position: relative;
    overflow: hidden;
}
.grid-item:hover .course-loop-wrapper .course-thumbnail img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.grid-item:hover .course-loop-badge-level.intermediate .badge-text {
    background: rgb(99, 204, 233);
    color: #0e1212;
}
.grid-item:hover .course-loop-title-collapse-2-rows a {
    color: #c99608;
}
.grid-item .course-loop-wrapper .course-thumbnail {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    width: 100%;
    max-height: 190px;
}
body a {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.grid-item .course-loop-wrapper .course-thumbnail a img {
    width: 100%;
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    height: 190px;
    object-fit: cover;
}
.course-loop-badges {
    position: absolute;
    top: 10px;
    left: 10px;
}
.tutor-course-badge.hot {
    background: #d31819;
}
.tutor-course-badge {
    display: inline-block;
    vertical-align: top;
    margin: 0 5px 3px 0;
    padding: 5px 7px;
    min-height: 0;
    color: #fff;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    line-height: 1.15;
    text-transform: uppercase;
    letter-spacing: .5px;
    border-radius: 2px;
    min-width: 48px;
}
.grid-item .course-loop-wrapper .course-loop-info {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 16px 12px 10px;
}
.course-loop-badge-level {
    margin: 0 0 10px;
}
.course-loop-badge-level.intermediate .badge-text {
    background: rgba(27, 243, 75, 0.37);
    color: #31593a;
}
.course-loop-badge-level .badge-text {
    display: inline-block;
    font-size: 13px;
    line-height: 18px;
    font-weight: var(--edumall-typography-headings-font-weight);
    border-radius: 2px;
    padding: 2px 10px;
    background: #252525;
    color: #fff;
}
.course-loop-category {
    color: #0075e3;
    margin: 0 0 1px;
}
.course-loop-category a {
    color: inherit;
}
.course-loop-title {
    font-size: 18px;
    line-height: 1.3;
    color: #2c3749;
    margin: 6px 0px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 44px;
}
.course-loop-title-collapse-2-rows a {
    display: inline !important;
    color: initial;
    font-weight: 600;
}
.course-loop-wrapper .price {
    font-weight: 600;
    font-size: 26px;
}
.single-input-wrap {
    height: 48px;
    line-height: 48px;
    max-width: 580px;
    border-radius: 8px;
    position: relative;
    
}
.user-area {
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 15px;
}
.user-area .user-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.user-area .user-details img {
    width: auto;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-right: 8px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}
.progress_mycourse {
    bottom: 9px;
    padding: 0px;
    position: absolute;
}
.progress_mycourse .progress{
    height: 1rem; 
}
 .progress{
    background-color: #cecece;
    height:21px;
    max-width: 93%;
}
.user-rating {
    margin-left: auto;
}
.user-rating span {
    color: #f2994a;
    margin-right: 3px;
}
.single-input-wrap input {
    height: 44px;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    width: 100%;
    color: #444;
    background: white;
    font-size: 15px;
    padding: 0 14px;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    position: relative;
}
.single-input-wrap button {
    position: absolute;
    width: 56px;
    height: 100%;
    border-radius: 0 8px 8px 0;
    background: transparent;
    border: 0;
    color: rgb(2, 117, 225);
    right: 0;
}
.single-input-wrap::after {
    content: "";
    position: absolute;
    right: 56px;
    height: 28px;
    width: 1px;
    background: #d9dee3;
    top: 10px;
}
.course-loop-info .price-wrap {
    font-weight: 500;
    padding: 5px 5px 5px;
    border-top: 1px solid #c0c0c0;
}
.price-wrap .price {
    color: #0071dc;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.price-wrap .course_type {
    color: #555;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
}

  .pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #adb5bd;
    padding: 8px 15px;
    outline: none;
    cursor: pointer;
    margin: 3px;
    text-transform: uppercase;
    border-radius: 5px;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #34cad2 ;
    border-color: #34cad2;
    outline: none ;
    
  }
  .pagination > li > a, .pagination > li > span{
    color: #47ccde
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: 2px
  }
  .filter_course .card-header {
    padding: 6px 15px;
}
.filter-icon {
    float: right;
}
@media screen and ( max-width: 767px ){
    .page-sidebar{width: auto;margin: auto;}
    .breadcrumb{background-color: #ffffffd4;}
    .p-120{padding-top: 40px;padding-bottom: 80px;}
    .course-one__single {margin-bottom: 15px; padding: 0px 10px;}
}