.login_warper_main {
    max-width: 350px;
    margin: 0px auto;
}
/* .emailLogin{
    max-width: 350px;
    margin: 0px auto;
    width: 100%;
}
.passLogin{
    max-width: 350px;
    margin: 0px auto;
    width: 100%;
}*/
.btnLogin{
    max-width: 350px;
    margin: 0px auto;
    width: 100%;
}
.login-btn {
    background-color: transparent;
    color: #000 !important;
    box-shadow: none !important;
} 
.custom-control-label::before{
    top: 0.5rem !important;
 }
 .custom-control-label::after{
    top: 0.5rem !important;
 }
 .user-image-c img.img-fluid {
    max-height: 60px;
}
.googleBtn{
box-sizing: border-box;
display: -webkit-flex;
padding: 5px 18px;
display: flex;
-webkit-align-items: center;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
cursor: pointer;
margin-right: auto;
margin-left: auto;
border-radius: 10px;
transition: background .15s;
    color: #000;
    max-width: 350px;
    border: 1px solid #ddd;
    width: 100%;
}
.googleBtn img {
    width: 20px;
    margin-right: 9px;
}
.orLogin h3{
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: center;
    color:#5e5c5c;
    padding-top: -10px;
}
.signLogin h2{
    padding-top: 20px;
    padding-bottom: 20px;
    /* margin-left: 70px; */
    text-align: center;
    font-weight: 600;
}
.forgotLog{
    width: 100%;
    text-align: right;
}
.orLogin {
    text-transform: uppercase;
    margin-top: 15px;
    color: #000;
}
.forgotLog a{font-size: 14px;}
.signupLog{
    margin: 20px auto;
    max-width: 350px;
    width: 100%;
    text-align: center;
}
.forgotLogin h2{
    padding-top: 20px;
    padding-bottom: 20px;
    /* margin-left: 70px; */
    text-align: center;
    font-weight: 500;
}