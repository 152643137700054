body {
    font-family: "Poppins";
    color: #81868a;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: -0.02em;
  }
  
  a:active,
  a:hover,
  a:focus,
  a:visited {
    text-decoration: none;
  }
  
  .page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
  }
  
  
  .block-title {
    text-align: center;
    margin-bottom: 60px;
  }
  .block-title__title {
    margin: 0;
    color: #252729;
    font-size: 40px;
    font-weight: bold;
  }
  
  .btn {
    /* display: inline-block;
    vertical-align: middle;
    border: none;
    outline: none;
    background-color: #26C9D3;
    font-size: 15px;
    font-weight: bold;
    color: #fff; */
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .btn:hover {
    background-color: #fff;
    color: #012237;
  }
  
  .post-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .post-pagination a {
    border-radius: 50%;
    background-color: #f1f1f1;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding: 8px 19px;
    color: #012237;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .post-pagination a:hover, .post-pagination a.active {
    background-color: #012237;
    color: #fff;
  }
  .post-pagination a + a {
    margin-left: 15px;
  }
  
  .tooltip-inner {
    -webkit-box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #81868a;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 48px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .bs-tooltip-auto[x-placement^=top] .arrow::before,
  .bs-tooltip-top .arrow::before {
    border-top-color: #fff;
  }
  
  /* .preloader {
    position: fixed;
    background-color: #fff;
    background-image: url(/images/preloader.gif);
    background-position: center center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9991;
  } */
  
  .scroll-to-top {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: #26C9D3;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    text-align: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    display: none;
    border-radius: 50%;
    transition: all .4s ease;
  }
  .scroll-to-top i {
    color: #fff;
    font-size: 18px;
    line-height: 45px;
  }
  .scroll-to-top:hover {
    background-color: #012237;
  }
  .scroll-to-top:hover i {
    color: #fff;
  }
  
  /* Cursor Style */
  .cursor {
    position: absolute;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    z-index: 10000;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: hidden;
  }
  
  .cursor {
    visibility: visible;
  }
  
  .cursor.active {
    opacity: 0.5;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  
  .cursor.hovered {
    opacity: 0.08;
  }
  
  .cursor-follower {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    z-index: 10000;
    visibility: hidden;
  }
  
  .cursor-follower {
    visibility: visible;
  }
  
  .cursor-follower.active {
    opacity: 0.7;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  .cursor-follower.hovered {
    opacity: 0.08;
  }
  
  .cursor-follower.close-cursor:before {
    position: absolute;
    content: '';
    height: 25px;
    width: 2px;
    background: #fff;
    left: 48%;
    top: 12px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    display: inline-block;
  }
  
  .cursor-follower.close-cursor:after {
    position: absolute;
    content: '';
    height: 25px;
    width: 2px;
    background: #fff;
    right: 48%;
    top: 12px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  /*
  *  header styles
  */
  .topbar-one {
    background-color: #fff;
  }
  .topbar-one .container {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .topbar-one__right, .topbar-one__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .topbar-one__right a, .topbar-one__left a {
    color: #252729;
    font-size: 13px;
    font-weight: 600;
    -webkit-transition: color .4s ease;
    transition: color .4s ease;
  }
  .topbar-one__right a:hover, .topbar-one__left a:hover {
    color: #34cad2;
  }
  .topbar-one__right a + a, .topbar-one__left a + a {
    margin-left: 15px;
  }
  
  .header-navigation {
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    padding: 0;
    position: relative;
    background-color: #34cad2;
  }
  .header-navigation .container {
    background: transparent;
    position: relative;
    display: block;
  }
  .header-navigation .container .logo-box {
    float: left;
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header-navigation .container .navbar-brand {
    height: auto;
    margin: 0;
    line-height: 1;
  }
  .header-navigation .container .menu-toggler {
    display: none;
  }
  .header-navigation .container .right-side-box {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
  }
  .header-navigation .main-navigation {
    float: none;
    text-align: right;
    /* padding-right: 90px; */
  }
  @media (min-width: 1200px) {
    .header-navigation .main-navigation {
      display: block !important;
    }
  }
  
  .header-navigation ul.navigation-box {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .header-navigation ul.navigation-box li a .sub-nav-toggler {
    display: none;
  }
  .header-navigation ul.navigation-box > li {
    position: relative;
    padding: 20px 0;
    display: inline-block;
    vertical-align: middle;
    /* Second Level Menu */
    /* Thrid Level Menu */
  }
  .header-navigation ul.navigation-box > li + li {
    margin-left: 48px;
  }
  .header-navigation ul.navigation-box > li:first-child {
    padding-left: 0;
  }
  .header-navigation ul.navigation-box > li:last-child {
    padding-right: 0;
  }
  .header-navigation ul.navigation-box > li > a {
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
    text-transform: capitalize;
    letter-spacing: -0.02em;
    font-size: 16px;
    font-weight: 500;
    color: #252729;
    position: relative;
  }
  .header-navigation ul.navigation-box > li.current > a, .header-navigation ul.navigation-box > li:hover > a {
    color: #fff;
  
  }
  .header-navigation ul.navigation-box > li > .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 911;
    float: left;
    min-width: 220px;
    padding: 0px 0px;
    text-align: left;
    list-style: none;
    background-color: #012237;
    background-clip: padding-box;
    opacity: 0;
    border-radius: 0px;
    visibility: hidden;
    -webkit-transition: opacity .4s ease, visibility .4s ease;
    transition: opacity .4s ease, visibility .4s ease;
    -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 1200px) {
    .header-navigation ul.navigation-box > li > .sub-menu {
      display: block !important;
    }
  }
  .header-navigation ul.navigation-box > li > .sub-menu.right-align {
    left: auto;
    right: 0;
  }
  .header-navigation ul.navigation-box > li > .sub-menu.center-align {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-navigation ul.navigation-box > li > .sub-menu > li {
    display: block;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .header-navigation ul.navigation-box > li > .sub-menu > li + li {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header-navigation ul.navigation-box > li > .sub-menu > li > a {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    padding: 12px 30px;
    display: block;
    line-height: 26px;
    white-space: nowrap;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .header-navigation ul.navigation-box > li > .sub-menu > li:hover > a {
    color: #fff;
    background: #34cad2;
  }
  .header-navigation ul.navigation-box > li:hover:before {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
  .header-navigation ul.navigation-box > li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
  }
  .header-navigation ul.navigation-box > li > ul > li {
    /* no more nested showen */
  }
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu {
    position: absolute;
    top: 0%;
    left: 100%;
    z-index: 911;
    float: left;
    min-width: 220px;
    padding: 0px 0px;
    text-align: left;
    list-style: none;
    background-color: #012237;
    background-clip: padding-box;
    opacity: 0;
    border-radius: 0px;
    visibility: hidden;
    -webkit-transition: opacity .4s ease, visibility .4s ease;
    transition: opacity .4s ease, visibility .4s ease;
    -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 1200px) {
    .header-navigation ul.navigation-box > li > ul > li > .sub-menu {
      display: block !important;
    }
  }
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu.right-align {
    left: auto;
    right: 100%;
  }
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu.center-align {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu > li {
    display: block;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu > li + li {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu > li > a {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    padding: 12px 30px;
    display: block;
    line-height: 26px;
    white-space: nowrap;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  
  
  .header-navigation ul.navigation-box > li > ul > li ul {
    display: none;
  }
  
  .stricked-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #34cad2;
    -webkit-box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
    opacity: 0;
    -webkit-transform: translateY(-110%);
            transform: translateY(-110%);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .stricked-menu ul.navigation-box > li {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .stricked-menu.stricky-fixed {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  
  .site-header {
    position: relative;
  }
  
  .site-header__decor {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    z-index: 99;
  }
  
  .site-header__decor-row {
    margin-left: -7px;
    margin-right: -7px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .site-header__decor-single {
    padding-left: 7px;
    padding-right: 7px;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33333%;
            flex: 1 1 33.33333%;
    max-width: 33.33333%;
  }
  
  
  
  .header__search-btn {
    width: 45px;
    height: 45px;
    border-radius: 56%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #fff;
    font-size: 18px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
    color: #252729;
  }
  .header__search-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #34cad2;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .header__search-btn:hover {
    color: #252729;
  }
  .header__search-btn:hover:before {
    left: 0;
  }
  
  .header__search-form {
    background-color: #f1f1f1;
    width: 100%;
    max-width: 410px;
    height: 68px;
    position: relative;
    margin-left: 60px;
  }
  .header__search-form input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    padding-left: 30px;
  }
  .header__search-form button[type=submit] {
    border: none;
    outline: none;
    width: 46px;
    height: 46px;
    border-radius: 56%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #34cad2;
    font-size: 18px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: absolute;
    color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 10px;
  }
  .header__search-form button[type=submit]::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #34cad2;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .header__search-form button[type=submit]:hover {
    color: #fff;
  }
  .header__search-form button[type=submit]:hover:before {
    left: 0;
  }
  
  .btn_div .custome_btn_ {
      background-color: #34cad2;
      color: #252729;
      padding: 12px 18px;
      font-weight: 500;
      text-transform: capitalize;
      font-size: 18px;
  }
  .btn_div {
      position: relative;
  }
  .btn_div .custome_btn_::before {
      content: '';
      position: absolute;
      width: 160px;
      height: 50px;
      border: 1px solid #252729;
      z-index: -1;
      top: 0px;
  }
  .form-count-one{position: relative;}
  .banner-wrapper {
    position: relative;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .banner-wrapper {
      overflow: hidden;
    }
  }
  .theme-btn {
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    padding: 0.7em 1em;
    background: #34cad2;
    border: 1px solid #34cad2;
    border-radius: 0.05em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
    display: inline-block;
    position: relative;
    min-width: 200px;
    border-radius: 0px !important;
}
.theme-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -4px;
  left: 6px;
  background: #fff;
  border-radius: inherit;
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}
.black_line::before {
  border: 1px solid #87e5eb;
  background-color: #c4fcff;
}
.theme-btn:hover {
  background: #b4ebee;
  color: #000;
  border-color:#fff ;
  transform: translate(0, 0.25em);
}
.theme-btn:active {
  background: #ffffff;
  transform: translate(0em, 0.75em);
  left: 0px;
}
.theme-btn:active::before {
  /* box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2; */
  transform: translate3d(0, 0, -1em);
  left: 0px;
}
  .carousel-inner{max-height: 100vh;}
  .carousel-caption{    bottom: auto;
      top: 50%;
      transform: translate(0px , -50%);
    }
  .carousel-caption p {
      /* font-family: "Satisfy"; */
      font-size: 48px;
  }
  .carousel-item::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0px;
      width: 100%;
      height: 100%;
      /* background-color: #0000007d; */
  }
  .carousel-caption h3 {
      font-size: 80px;
      text-transform: uppercase;
      font-weight: 700;
  }
  .carousel-caption h3 span
  {
    color: #34cad2;
  }
  
  
  
  /*
  * 8. team styles
  */
  .team-one {
    padding-bottom: 90px;
    padding-top: 120px;
  }
  .team-one__single {
    margin-bottom: 30px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .team-one__single:hover {
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .team-one__single:hover .team-one__image > img {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  .team-one__image {
    text-align: center;
    margin-bottom: -103px;
  }
  .team-one__image img {
    border-radius: 50%;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .team-one__content {
    text-align: center;
    border: 2px solid #f1f1f1;
    padding-bottom: 30px;
    padding-top: 135px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .team-one__social {
    background-color: #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }
  .team-one__social a {
    font-size: 14px;
    color: #81868a;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .team-one__social a:hover {
    color: #34cad2;
  }
  .team-one__social a + a {
    margin-left: 28px;
  }
  
  .team-one__name {
    font-size: 18px;
    font-weight: 600;
    color: #012237;
    margin: 0;
    margin-bottom: -5px;
  }
  .team-one__name a {
    color: inherit;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .team-one__name a:hover {
    color: #34cad2;
  }
  
  .team-one__designation {
    font-size: 12px;
    text-transform: uppercase;
    color: #81868a;
    letter-spacing: .2em;
    line-height: 1em;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  
  .team-one__text {
    margin: 0;
    font-size: 14px;
    color: #81868a;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: -10px;
  }
  
  .team-details {
    padding-top: 120px;
    padding-bottom: 90px;
  }
  .team-details .team-one__image {
    margin-bottom: 0;
  }
  .team-details .team-one__image img {
    border-radius: 0;
  }
  .team-details .team-one__content {
    padding-top: 30px;
  }
  .team-details .team-one__designation {
    margin-bottom: 0;
  }
  .team-details__title {
    margin: 0;
    color: #012237;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .team-details__text {
    margin: 0;
    color: #81868a;
    font-size: 16px;
    line-height: 34px;
    font-weight: 500;
  }
  .team-details__subtitle {
    margin: 0;
    color: #012237;
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .team-details__certificate-list {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
  }
  .team-details__certificate-list li + li {
    margin-left: 25px;
  }
  
  .progress-one__single + .progress-one__single {
    margin-top: 20px;
  }
  .progress-one__title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #012237;
    line-height: 1em;
  }
  .progress-one__percent {
    margin: 0;
    font-size: 20px;
    color: #34cad2;
    /* font-family: "Satisfy"; */
    line-height: 1em;
  }
  .progress-one__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 20px;
  }
  .progress-one__bar {
    width: 100%;
    height: 5px;
    background-color: #f1f1f1;
    position: relative;
    overflow: hidden;
  }
  .progress-one__bar span {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #34cad2;
    height: 100%;
  }
  
  .team-tab {
    position: relative;
    background-color: #022c46;
  }
  .team-tab:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-image: url(/images/patter-1-1.jpg); */
    opacity: 0.08;
  }
  .team-tab .container {
    position: relative;
  }
  .team-tab__content {
    padding-right: 80px;
  }
  .team-tab__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .team-tab__title {
    margin: 0;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
  }
  .team-tab__designation {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .2em;
    color: #94a2ab;
    margin: 0;
  }
  .team-tab__text {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    color: #94a2ab;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .team-tab__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .team-tab__social a {
    font-size: 18px;
    color: #fff;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .team-tab__social a:hover {
    color: #34cad2;
  }
  .team-tab__social a + a {
    margin-left: 38px;
  }
  .team-tab .progress-one__bar {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .team-tab .progress-one__percent {
    color: #fff;
  }
  .team-tab .progress-one__title {
    color: #fff;
  }
  .team-tab__btn {
    font-size: 18px;
    padding: 21.5px 47.5px;
    margin-top: 40px;
  }
  .team-tab__tab-navs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 100px;
    right: 20%;
    border: none;
  }
  .team-tab__tab-navs img {
    border-style: solid;
    border-width: 2px;
    border-color: #fff;
    border-radius: 50%;
    background-color: #012237;
    -webkit-box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.2);
    width: 82px;
    opacity: 0.4;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .team-tab__tab-navs .active img {
    opacity: 1;
  }
  .team-tab__tab-navs li + li {
    margin-left: 10px;
  }
  
  /*
  * 9. pricing styles
  */
  .pricing-one {
    background-color: #f1f1f1;
    padding-top: 120px;
    padding-bottom: 90px;
  }
  .pricing-one [class*=col-lg-]:nth-child(1) .pricing-one__tag-line,
  .pricing-one [class*=col-lg-]:nth-child(1) .pricing-one__price {
    color: #34cad2;
  }
  .pricing-one [class*=col-lg-]:nth-child(1) .pricing-one__single:after {
    border-color: #34cad2;
  }
  .pricing-one [class*=col-lg-]:nth-child(2) .pricing-one__tag-line,
  .pricing-one [class*=col-lg-]:nth-child(2) .pricing-one__price {
    color: #34cad2;
  }
  .pricing-one [class*=col-lg-]:nth-child(2) .pricing-one__single:after {
    border-color: #34cad2;
  }
  .pricing-one [class*=col-lg-]:nth-child(3) .pricing-one__tag-line,
  .pricing-one [class*=col-lg-]:nth-child(3) .pricing-one__price {
    color: #7c4bc0;
  }
  .pricing-one [class*=col-lg-]:nth-child(3) .pricing-one__single:after {
    border-color: #7c4bc0;
  }
  
  .pricing-one__single {
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 60px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 60px;
  }
  .pricing-one__single::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 20px);
    height: 100%;
    background-color: #fff;
    z-index: 10;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .pricing-one__single::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    height: 100%;
    border: 2px solid #34cad2;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .pricing-one__single:hover:before {
    right: 20px;
  }
  .pricing-one__single:hover::after {
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  }
  
  .pricing-one__inner {
    position: relative;
    z-index: 11;
  }
  
  .pricing-one__price {
    font-size: 60px;
    font-weight: 400;
    margin: 0;
    line-height: 1em;
    color: #34cad2;
    margin-bottom: -15px;
  }
  
  .pricing-one__name {
    font-size: 14px;
    font-weight: 600;
    color: #012237;
    text-transform: uppercase;
    letter-spacing: .2em;
    margin: 0;
    line-height: 1em;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  
  .pricing-one__list {
    margin: 0;
    border-top: 1px solid #f1f1f1;
    padding-top: 40px;
    margin-bottom: 35px;
  }
  .pricing-one__list li {
    color: #81868a;
    font-size: 16px;
    line-height: 1em;
  }
  .pricing-one__list li + li {
    margin-top: 30px;
  }
  
  .pricing-one__btn {
    background-color: #f1f1f1;
    color: #81868a;
    font-size: 16px;
    padding: 16.5px 48px;
    margin-bottom: 40px;
  }
  .pricing-one__btn:hover {
    background-color: #34cad2;
    color: #fff;
  }
  
  .pricing-one__tag-line {
    font-size: 12px;
    color: #34cad2;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .2em;
    line-height: 1em;
    margin: 0;
  }
  
  /*
  * 10. become-teacher styles
  */
  .become-teacher {
    padding-top: 120px;
  }
  
  .become-teacher__form {
      position: relative;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      /* box-shadow: 1px 4px 5px #b6b6b6; */
  }
  .signupLog a {
    display: block;
}.mh-50{min-height: 50px;}
  .become-teacher__form:before {
    content: '';
    width: 197px;
    height: 268px;
    /* background-image: url(/images/dot-pattern-1-3.png); */
    position: absolute;
    bottom: -11%;
    left: 87%;
    z-index: -1;
  }
  section.course-one.course-one__teacher-details.home-one::before {
          content: '';
      width: 197px;
      height: 268px;
      /* background-image: url(/images/dot-pattern-1-3.png); */
      position: absolute;
      bottom: 64%;
      left: 80%;
      z-index: -1;
      background-repeat: no-repeat;
  }
  .become-teacher__form-top {
    position: relative;
  }
  
  .become-teacher__form-title {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 24px;
      padding: 0px 0px 15px;
  }
  .become-teacher__form-content {
      padding-bottom: 30px;
  }
  .become-teacher__form input {
      border: none;
      outline: none;
      width: 100%;
      background-color: #f1f1f1;
      height: 50px;
      font-size: 16px;
      color: #81868a;
      font-weight: 500;
      padding-left: 40px;
      display: block;
      margin-bottom: 20px;
      border-radius: 3px;
  }
  .become-teacher__form input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #81868a;
  }
  .become-teacher__form input::-moz-placeholder {
    /* Firefox 19+ */
    color: #81868a;
  }
  .become-teacher__form input:-ms-input-placeholder {
    /* IE 10+ */
    color: #81868a;
  }
  .become-teacher__form input:-moz-placeholder {
    /* Firefox 18- */
    color: #81868a;
  }
  .become-teacher__form label {
    display: block;
  }
  .become-teacher__form-btn {
      font-size: 16px;
      color: #272529;
      padding: 10px 20px;
      width: 100%;
      background-color: #34cad2;
      border: 0px;
      /* margin-top: 18px; */
      border-radius: 3px;
  }
  .become-teacher__form-btn:hover {
    background-color: #012237;
    color: #fff;
  }
  
  .become-teacher__title {
    margin: 0;
    color: #012237;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 40px;
  }
  
  .become-teacher__subtitle {
    font-size: 20px;
    color: #012237;
    font-weight: 600;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .become-teacher__text {
    font-size: 16px;
    line-height: 34px;
    font-weight: 500;
    color: #81868a;
    margin: 0;
  }
  
  /*
  * 11. course styles
  */
  .course-one .post-pagination {
    margin-top: 30px;
  }
  /* .course-one__carousel .color-1 .course-one__category { */
    /* background-color: #7c4bc0; */
  /* } */
  /* .course-one__carousel .color-2 .course-one__category { */
    /* background-color: #1888c9; */
  /* } */
  /* .course-one__carousel .color-3 .course-one__category { */
    /* background-color: #d23f3f; */
  /* } */
  /* .course-one__carousel .color-4 .course-one__category { */
    /* background-color: #34cad2; */
  /* } */
  /* .course-one__carousel .color-5 .course-one__category { */
    /* background-color: #d23f3f; */
  /* } */
  /* .course-one__carousel .color-6 .course-one__category { */
    /* background-color: #33a9bb; */
  /* } */
  /* .course-one [class*=col-lg-]:nth-child(1) .course-one__category { */
    /* background-color: #34cad2; */
  /* } */
  /* .course-one [class*=col-lg-]:nth-child(2) .course-one__category { */
    /* background-color: #1888c9; */
  /* } */
  /* .course-one [class*=col-lg-]:nth-child(3) .course-one__category { */
    /* background-color: #d23f3f; */
  /* } */
  /* .course-one [class*=col-lg-]:nth-child(4) .course-one__category { */
    /* background-color: #34cad2; */
  /* } */
  /* .course-one [class*=col-lg-]:nth-child(5) .course-one__category { */
    /* background-color: #d23f3f; */
  /* } */
  /* .course-one [class*=col-lg-]:nth-child(6) .course-one__category { */
    /* background-color: #33a9bb; */
  /* } */
  .course-one__single {
    margin-bottom: 30px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
    z-index: 99;
    border-radius: 5px;
    overflow: hidden;
  }
  .course-one__single:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgb(0 0 0);
            box-shadow: 0px 0px 6px 0px rgb(0 0 0);
  }
  .course-one__image {
    position: relative;
  }
  .course-one__image > img {
    width: 100%;
max-height: 440px;
    object-fit: cover;
    display: block;
    margin: 0px auto;
  }
  .course-one__image i {
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .course-one__category {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    letter-spacing: .2em;
    background-color: #34cad2;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 27px;
    padding: 0 17px;
    position: absolute;
    left: 50px;
    top: -13.5px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .course-one__category:hover {
    color: #fff;
    background-color: #252729;
  }
  .course-one__content {
      padding-left: 12px;
      padding-right: 10px;
      position: relative;
      padding-top: 15px;
      padding-bottom: 18px;
      border-style: solid;
      border-width: 1px;
      border-color: #34cad2;
      border-top: 0;
      background-color: #34cad2;
  }
  .course-one__admin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #81868a;
    margin: 0;
    line-height: 1em;
    margin-bottom: 1px;
  }
  .course-one__admin > img {
    width: 21px !important;
    border-radius: 50%;
    margin-right: 10px;
  }
  .course-one__admin a {
    color: #34cad2;
    margin-left: 5px;
  }

  .course-one__image1 > img {
    width: 100%;
    height: 212px;
    object-fit: cover;
  }
  .course-one__stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .course-one__stars-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .course-one__stars-wrap i {
    font-size: 14px;
    color: #FFE816;
  }
  .course-one__title {
    font-size: 18px;
    font-weight: 600;
    color: #012237;
    margin: 0;
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
  }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #34cad2;
    border-color: #34cad2;
}
.btn-primary:hover {
  color: #fff;
  background-color: #34cad2;
  border-color: #34cad2;
}
  .course-one__title a {
    color: inherit;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .course-one__title a:hover {
    color: #fff;
  }
  .course-one__count {
    font-size: 14px;
    font-weight: 500;
    color: #012237;
    margin-left: 10px;
  }
  .course-one__stars-count {
    font-size: 11px;
    color: #000;
    font-weight: 500;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
  }
  .course-one__meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 1px solid #fff;
    padding-top: 15px;
    margin-top: 6px;
  }
  .course-one__meta a {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    line-height: 1em;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .course-one__meta a:hover {
    color: #34cad2;
  }
  .course-one__meta a:hover i {
    color: #012237;
  }
  .course-one__meta a:last-child {
    color: #012237;
    font-weight: 600;
  }
  .course-one__meta i {
    font-size: 14px;
    color: #fff;
    margin-right: 10px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .course-one__link {
    background-color: #fff;
    width: 100%;
    color: #272529;
    font-size: 15px;
    font-weight: 600;
    display: block;
    text-align: center;
    padding: 6px 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    margin-top: 20px;
  }
  .course-one__link:hover {
       background-color: #252729;
       color: #34cad2;
  }
  
  .course-one__teacher-details {
    padding-bottom: 90px;
    position: relative;
  }
  
  .course-one__top-title {
    padding: 60px 0;
    position: relative;
  }
  img.dotted_img {
    position: absolute;
    right: 0;
    top: -100px;
    width: 115px;
}
img.stripe_img {
  position: absolute;
  left: -6px;
  top: 8%;
  width: 120px;
}
  .course-one__carousel .owl-dots .owl-dot {
    outline: none;
  }
  .course-one__carousel .owl-nav.disabled + .owl-dots {
    margin-top: 30px;
  }
  .course-one__carousel .owl-dots .owl-dot:hover span,
  .course-one__carousel .owl-dots .owl-dot.active span {
    -webkit-transform: scale(1.56);
            transform: scale(1.56);
    opacity: 1;
    background-color: #012237;
  }
  .course-one__carousel .owl-dots .owl-dot span {
    width: 9px;
    height: 9px;
    background-color: #012237;
    margin: 0 2.5px;
    border-radius: 50%;
    opacity: 0.2;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
  }
  
  .course-one__top-title.home-three {
    padding-top: 120px;
    padding-bottom: 0;
  }
  .course-one__top-title.home-three:before {
    display: none;
  }
  
  .course-one.home-three {
    padding-top: 0;
    position: relative;
  }
  .course-one.home-three .course-one__line {
    display: none;
  }
  
  
  .thm-breadcrumb {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .thm-breadcrumb li {
    font-size: 16px;
    text-transform: capitalize;
  }
  .thm-breadcrumb li a {
    color: #fff;
  }
  .thm-breadcrumb li + li::before {
    content: '/';
    color: #fff;
    margin-left: 15px;
    margin-right: 13px;
  }
  
  /*
  * 13. footer styles
  */
  .site-footer {
    background-color: #022c46;
    /* background-image: url(/images/banner3.jpg); */
    background-image: url(../css/images/banner3.jpg);
    position: relative;
  }
  .site-footer::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: #00000080;
  }
  .site-footer__bottom {
    background-color: #26c9d396;
    position: relative;
  }
  .site-footer__bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .site-footer__copy {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
  }
  .site-footer__copy a {
    color: #FFE816;
    margin-left: 10px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .site-footer__copy a:hover {
    color: #fff;
  }
  .site-footer__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 0;
    padding-right: 70px;
    padding-left: 30px;
    position: relative;
  }
  .site-footer__social a {
      color: #000;
      font-size: 20px;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
      position: relative;
      z-index: 10;
      width: 35px;
      height: 35px;
      background-color: #fff;
      text-align: center;
      border-radius: 50px;
  }
  .site-footer__social a:hover {
    color: #26C9D3;
  }
  .site-footer__social a + a {
    margin-left: 30px;
  }
  .site-footer__scroll-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute !important;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
  .site-footer__scroll-top i {
    font-size: 22px;
    color: #012237;
  }
  .site-footer__scroll-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #fff;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .site-footer__scroll-top:hover {
    color: #26C9D3;
  }
  .site-footer__scroll-top:hover:before {
    left: 0;
  }
  a.custome_btn_.footer_btn {
      height: 56px;
      display: inline-block;
      border-radius: 5px;
      color: #fff;
      font-weight: 400;
  }
  .footer-widget {
    padding-top: 80px;
    padding-bottom: 70px;
  }
  /* .footer-widget-respo {
    padding-top: 10px;
    padding-bottom: 70px;
  } */
  .footer-widget .list-unstyled {
    margin: 0;
  }
  .footer-widget__text {
    margin: 0;
    font-size: 15px;
    line-height: 30px;
    color: #94a3ac;
    margin-top: -10px;
  }
  .right_side_value a {
    font-size: 14px;
}
  .footer-widget__about * {
    position: relative;
  }
  .footer-widget__btn-block .right_side_value {
      float: right;
      width: calc(100% - 50px);
      margin-top: -5px;
  }
  .footer-widget__title {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1em;
    margin-bottom: 35px;
  }
  .footer-widget__link-list li::before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #26c9d3;
      position: absolute;
          top: 8px;
  }
  .footer-widget__link-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .footer-widget__gallery {
    position: relative;
  
  }

  .footer-widget__gallery-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-top: -10px;
  }
  .footer-widget__gallery-list li {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33333%;
            flex: 1 1 33.33333%;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .footer-widget__gallery-list li a {
    display: block;
  }
  .footer-widget__gallery-list li a img {
    width: 100%;
  }
  .footer-widget__btn-block {
    margin-top: 30px;
  }
  .footer-widget__btn-block .thm-btn:nth-child(2) {
    background-color: #34cad2;
    margin-left: 10px;
  }
  .footer-widget__btn-block .thm-btn:nth-child(2):hover {
    background-color: #fff;
    color: #012237;
  }
  .footer-widget__link {
    position: relative;
  }
  .footer-widget__link-list li {
    line-height: 1.5em;
    position: relative;
  }
  .footer-widget__link-list li + li {
    margin-top: 20px;
  }
  .footer-widget__link-list li a {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    padding-left: 25px;
  }
  .footer-widget__link-list li a:hover {
    color: #26C9D3;
  }
  .footer-widget__course-list li + li {
    margin-top: 38px;
  }
  .footer-widget__course-list li h2 {
    margin: 0;
    font-size: 15px;
    color: #94a3ac;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .footer-widget__course-list li h2 a {
    color: inherit;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .footer-widget__course-list li p {
    margin: 0;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
  }
  
  .form_register {
    position: relative;
    padding: 120px 0;
    background-image: url(../css/images/banner3.jpg);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
  }
  .form_register::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #012237;
    opacity: 0.6;
  }
  .form_register .container {
    position: relative;
  }
  .form_register__title {
  font-size: 36px;
      color: #fff;
      font-weight: 600;
      margin: 0;
      margin-bottom: 30px;
  }
  .form_register__content p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
  }
  .form_register__content h3 {
      /* font-family: "Satisfy"; */
      color: #26c9d3;
      font-size: 30px;
  }
  
  
  .form-count-one {
    /* padding-bottom: 120px; */
  }
  .form-count-one .become-teacher__form {
    margin-top: -350px;
    z-index: 99;
  }
  .form-count-one__content {
    padding-top: 40px;
  }
  .form-count-one__title {
      margin: 0;
      color: #012237;
      font-size: 28px;
      font-weight: 600;
      margin-top: -15px;
  }
  .form-count-one__tag-line {
      margin: 0;
      font-size: 18px;
      color: #222;
      margin-top: 5px;
  }
  .categories-top {
      position: relative;
      display: block;
      padding: 40px 0px 120px;
  
  }
  .section-title__tagline {
      /* font-family: "Satisfy"; */
      color: #26c9d3;
      font-size: 24px;
  }
  .section-title__title {
      font-size: 43px;
      line-height: 1.2em;
      font-weight: 600;
      text-transform: capitalize;
      color: #252729;
  }
  .categories-top .section-title {
      margin-bottom: 42px;
  }
  .top_category_ {
    border: 1.5px solid #ebebf2;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s all linear;
    margin: 10px 0px;
    padding: 25px 0px;
    cursor: pointer;
    height: 100%;
}
.category-list_div.col {
  padding: 6px;
}
.category_icon {
  width: 65px;
  height: 65px;
  text-align: center;
  margin: 0px auto;
  border: 1px solid rgb(136 149 163 / 25%);
  display: flex;
  color: #ff7a1a;
  border-radius: 50%;
  justify-content: center;
}
.category_icon img{
  width: 38px;
  height: 40px;
  align-self: center;
}
.top_category_ h2 {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
  color: #202020;
}
.top_category_ h5 {
  color: #8f8fa3;
  font-size: 15px;
  line-height: 21px;
}
.top_category_:hover .category_icon{
  background-color: #34cad2;
  color:#fff;
}
.top_category_:hover {
  -webkit-box-shadow : 0px 16px 72px 0px #0000001f;
  box-shadow : 0px 16px 72px 0px #0000001f;
  border-color : transparent;
}
.top_category_:hover .two{
  background-color:#ffc91b;
  color:#fff;
} 
.two{
  border-Color: rgba(255,201,27,0.2); color:#ffc91b;
}
.top_category_:hover .three{
  background-color:#1089ff;
  color:#fff;
} 
.three{
  border-Color: rgba(16,137,255,0.2);  color:#1089ff;
}
.top_category_:hover .four{
  background-color:#00af91;
  color:#fff;
}
.four{
  border-Color: rgba(0,175,145,0.2);color:#00af91;
}
.top_category_:hover .five{
  background-color:#1089ff;
  color:#fff;
}
.five{
  border-Color: rgba(16,137,255,0.2);color:#1089ff;
}

.category_icon i{align-self: center;font-size: 30px}
  .section-title {
      position: relative;
      display: block;
      margin-top: -7px;
      margin-bottom: 35px;
  }
  .categories-top__wrapper {
      position: relative;
      display: block;
  }
  .categories-top__single {
      position: relative;
      display: block;
      margin-bottom: 30px;
  }
  .categories-top__single-img {
      position: relative;
      display: block;
      overflow: hidden;
      border-radius: 8px;
  }
  .categories-top__single-img::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: "";
      background-color: #000000a8;
      -webkit-transition: all 500ms ease;
      transition: all 500ms ease;
      transform: scaleY(0);
      opacity: 0;
      z-index: 1;
  }
  .categories-top__single-img img {
      width: 100%;
  }
  .categories-top__single-overlay {
      position: absolute;
      left: 20px;
      bottom: 20px;
      -webkit-transition: all 500ms ease;
      transition: all 500ms ease;
      transform: scaleY(0);
      z-index: 1;
  }
  .categories-top__single-overlay-text1 {
      position: relative;
      display: block;
  }
  .categories-top__single-overlay-text1 p {
      position: relative;
      display: inline-block;
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      background: #252729;
      padding: 10px 20px 6px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin: 0;
  }
  .categories-top__single-overlay-text2 {
      position: relative;
      display: block;
  }
  .categories-top__single-overlay-text2 h4 {
      position: relative;
      display: inline-block;
      color: #ffffff;
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
      padding: 15px 75px 10px 20px;
      background: #26c9d3;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
  }
  .categories-top__single:hover .categories-top__single-img::before {
      opacity: 1;
      transform: scaleY(1);
  }
  .categories-top__single:hover .categories-top__single-overlay {
      transform: scaleY(1);
  }
  .out_count_{
    background-image: url(../css/images/bg4.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 100px 0px 80px;
  }
  .out_count_::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-color: #0000009e;
      width: 100%;
      height: 100%;
  }
  .value-content h2 {
      font-size: 60px;
      line-height: 1.1;
      font-weight: 600;
      margin-bottom: 10px;
      color: #fff;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) { 
    .form-count-one .become-teacher__form{
      margin-top: -250px;
    }
    .carousel-caption h3{font-size: 45px;}
    .block-title__title{font-size: 30px;}
    .value-content h2{font-size: 40px;}
    .value-content p {font-size: 16px !important;}
    
  }
  @media screen and (max-width:1200px) {
    .category-list_div.col {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 8px;
    }
  }
  @media screen and (max-width:1100px) {
    .category-list_div.col {
      flex: 0 0 33%;
      max-width: 33%;
      padding: 8px;
    }
    .carousel-caption h3{
      font-size: 50px;
    }
  }
  @media screen and (max-width:1024px) {
    .category-list_div.col {
      flex: 0 0 33%;
      max-width: 33%;
      padding: 8px;
    }
    .carousel-caption h3{
      font-size: 40px;
    }
  }
  @media screen and (max-width:767px) {
    .category-list_div.col {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media screen and (max-width: 400px){
    .category-list_div.col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .value-content h2 {
      font-size: 40px !important;
    }
    .categories-top {
      position: relative;
      display: block;
  }

  }
  .value-content p {
      font-size: 20px;
      color: #fff;
  }
  .teacher_list {
      padding: 60px 0px;
      background-color: #252729;
      color: #fff;
  }
  h2.heading_tea {
      color: #26C9D3;
      margin: 5px;
      font-size: 36px;
  }
  .dutra-team1 {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 5px 5px 30px 0 #d0cfcf1c;
      transition: all .5s;
  }
  .dutra-media {
      background-color: #fff;
      position: relative;
  }
  
  .dutra-media {
      overflow: hidden;
  }
  .dutra-media img, .dutra-post-media img {
      width: 100%;
      height: auto;
  }
  .dutra-media img{height: 350px;object-fit: cover;}
  .dutra-team1 .dutra-info {
      position: absolute;
      bottom: -55px;
      background: #fff;
      width: 100%;
      padding: 20px 30px;
      text-align: center;
      transition: all .8s;
      -moz-transition: all .8s;
      -webkit-transition: all .8s;
      -ms-transition: all .8s;
      -o-transition: all .8s;
  }
  .dutra-team1 .dutra-title {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 0px;
      font-weight: 600;
      color: #242424
  }
  .dutra-team1 .dutra-position {
      display: block;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 12px;
      color: #26c9d3;
  }
  .dutra-team1 .dutra-social-icon {
      display: block;
      margin-top: 20px;
  }
  .dutra-social-icon {
      display: inline-block;
      margin: 0 -3px;
      padding: 0;
      text-align: center;
  }
  .dutra-team1 .dutra-info:after {
      content: "";
      width: 15px;
      height: 15px;
      background: #fff;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%) rotate(
  45deg);
      -moz-transform: translateX(-50%) rotate(45deg);
      -webkit-transform: translateX(-50%) rotate(
  45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
      -o-transform: translateX(-50%) rotate(45deg);
  }
  .dutra-social-icon li {
      margin-left: 2px;
      margin-right: 2px;
  }
  .dutra-social-icon li {
      display: inline-block;
      padding: 0;
      font-size: 12px;
  }
  .dutra-team1 .dutra-social-icon li a {
      width: 32px;
      height: 32px;
      border: 1px solid #0003;
      border-radius: 4px;
      font-size: 16px;
      line-height: 30px;
      padding: 0;
  }
  .dutra-social-icon li {
      margin-left: 2px;
      margin-right: 2px;
  }
  .dutra-title a {
      color: #272529;
      text-transform: capitalize;
  }
  .inner-banner {
      background-color: #011928;
      background-repeat: no-repeat;
      background-size: cover;
      /* background-attachment: fixed; */
      position: relative;
      background-image: url(../css/images/breadcrumb.jpg);
  }
  .inner-banner-responsive {
    background-color: #011928;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    position: relative;
    /* height: 150px; */
    background-image: url(../css/images/breadcrumb.jpg);
}
.inner-banner-responsive .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 35px;
}
  .inner-banner::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #011928;
      opacity: 0.5;
  }

  .thm-breadcrumb li a {
      color: #fff;
  }
  .thm-breadcrumb li.active a {
    color: #03d7e4;
    font-weight: 600;
    letter-spacing: 1px;
}
  .inner-banner__title {
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 600;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }
  .inner-banner .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    padding: 34px 15px;
  }
  
  
  .widget {
      margin-bottom: 30px;
  }
  .widget-inst {
    margin-bottom: 30px;
    padding-top: 30px;
padding-left: 50px;
}
  .widget .widget-title {
      margin-top: 0;
      margin-bottom: 20px;
      color: #272529;
          position: relative;
  }
  .text-theme-color-2{color: #34cad2;}
  .line-bottom:after {
      bottom: -10px;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      width: 50px;
  }
  .line-bottom:after{
    background: #34cad2 !important;
  }
  .widget .search-input {
      background: transparent none repeat scroll 0 0;
      border: 1px solid #c0c0c0;
      border-radius: initial;
      box-shadow: none;
      height: 45px;
      padding: 10px;
  }
  .widget .search-button {
      background: transparent none repeat scroll 0 0;
      border: 1px solid #c0c0c0;
      border-radius: 0;
      height: 45px;
          color: #272529;
      padding: 0px 15px;
  }
  .widget ul.list li, .widget .post {
      margin-bottom: 8px;
      padding-bottom: 8px;
  }
  .widget .list-border li {
      border-bottom: 1px dashed #d3d3d3;
  }
  ul.list li a {
      text-decoration: none;
      -webkit-transition: all 300ms ease-in;
      -o-transition: all 300ms ease-in;
      transition: all 300ms ease-in;
  }
  .widget .list-border li {
      border-bottom: 1px dashed #d3d3d3;
      position: relative;
  }
  .categories .angle-double-right li::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #26c9d3;
      top: 13px;
  }
  .categories .angle-double-right li a {
      color: #272529;
      padding-left: 20px;
      font-weight: 500;
  }
  .categories .angle-double-right {
      padding: 0px;
      list-style: none;
  }
  .p-120 {
      padding-top: 80px;
      padding-bottom: 120px;
  }
  .course-one.course-one__teacher-details.home-one::after {
         content: '';
      width: 236px;
      height: 168px;
      /* background-image: url(../../public/images/line-stripe.png); */
      background-image: url(../css/images/line-stripe.png);
      position: absolute;
      top: 22%;
      left: 6%;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: cover;
  }
  .course-page .row .col-xl-4.col-lg-6 {
      padding: 8px;
  }
  @media (min-width: 1400px){
    .container{
          max-width: 1440px;
    }
  }
  @media (min-width: 1300px){
    .container{
          max-width: 1340px;
    }
  }
  /* .carousel-indicate li{
     visibility: hidden;
     } */
  .team-page {
    padding-bottom: 40px;
}
.instrutores__single {
    margin-bottom: 30px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.instrutores__image {
    text-align: center;
    margin-bottom: -103px;
}
.instrutores__content {
    text-align: center;
    border: 2px solid #f1f1f1;
    padding-bottom: 30px;
    padding-top: 135px;
    padding-left: 40px;
    padding-right: 40px;
}
.instrutores__name {
    font-size: 18px;
    font-weight: 600;
    color: #012237;
    margin: 0;
    margin-bottom: -5px;
}
.instrutores__designation {
    font-size: 12px;
    text-transform: uppercase;
    color: #81868a;
    letter-spacing: .2em;
    line-height: 1em;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.instrutores__text {
    margin: 0;
    font-size: 14px;
    color: #81868a;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: -10px;
}
.instrutores__social {
    background-color: #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
}
.instrutores__social a {
    font-size: 14px;
    color: #81868a;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
        padding-right: 20px;
}
.instrutores__image img{
    border-radius: 50%;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(1);
    transform: scale(1);
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.instructor-item.type1:hover, .instructor-item.type2 {
  border-color: #f5f5f5;
}
.instructor-item, .instructor-item.column {
  margin-bottom: 30px;
  z-index: 1;
}
.instructor-item.type2 {
  text-align: center;
  padding: 40px 20px 35px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
}
.instructor-item.type2::before {
  position: absolute;
  content: "";
  display: block;
  pointer-events: none;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 0;
}
.instructor-item.type2::before{
  background-color: #f9f9f9;
}
.instructor-item.type2 img {
  float: none;
  display: inline-block;
}
.instructor-item.type2 > * {
  position: relative;
  z-index: 1;
}
.instructor-item img {
  width: 150px;
}
.instructor-item.type2 .instructor-item-meta-data {
  width: 100%;
  float: none;
  display: inline-block;
  margin-top: 15px;
}
.instructor-item.type2 > * {
  position: relative;
  z-index: 1;
}
.instructor-item.type2 .instructor-item-meta-data h4 {
  margin-bottom: 5px;
  font-size: 20px;
}
.instructor-item .instructor-item-meta-data h4 a {
  color: inherit;
}
.instructor-item.type2 .instructor-item-meta-data h5 {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 2px;
}
.instructor-item.type2 .instructor-item-meta-data .team-social-links {
  margin: 15px 0 10px;
}
.instructor-item.type2 .instructor-item-meta-data .team-social-links {
  float: none;
  display: inline-block;
  font-size: 17px;
}
.instructor-item.type2 .instructor-item-meta-data .team-social-links ul {
  margin-bottom: 0;
}
ul {
  list-style-position: inside;
  list-style-type: square;
}
.instructor-item.type2 .instructor-item-meta-data .team-social-links ul li {
  list-style: none;
  float: none;
  display: inline-block;
  margin: 0 8px -2px;
  padding-bottom: 0;
}
.instructor-item .team-social-links ul li a{
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #297ccd;
}
.instructor-item.type2:hover::before {
  transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
}
.instructor-item.type2:hover::after {
  transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.instructor-item.type2::after {
  position: absolute;
  content: "";
  display: block;
  pointer-events: none;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 0;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  opacity: 0;
  visibility: hidden;
  background-color: #f9f9f9;
}
.instructor-item.type2::before{
  background-color: #f9f9f9;
}
.instructor-item.type2::after{
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.instructor-item.type2:hover{
  border-color: #34cad2;
}
ul.teachers-details {
  margin-top: -10px;
  padding: 0;
}
ul.teachers-details li {
  display: inline-block;
  font-size: 16px;
  line-height: normal;
  width: 97%;
  position: relative;
  padding: 10px 0px 10px 25px;
  box-sizing: border-box;
}
ul.teachers-details li::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0px;
  width: 12px;
  height: 12px;
  border: 2px solid #34cad2;
  border-radius: 50%;
}
.instructor__name {
  font-size: 25px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: #34cad2;
  /* border-bottom: 1px solid #34cad2; */
  padding-bottom: 0.9rem;
  padding-top: 30px;
}
.instructor_detail {
font-size:  16px;
margin-bottom: 1.5rem;
font-weight: 30;
color: #000000;
border-bottom: 1px solid #38b1bd;
padding-bottom: 0.9rem;
padding-top: 15px;
}
.rating-star-instrutor i {
  color: #ffba00;
  font-size: 20px;
}
.rating-star-instrutor  {
color: #494242;
font-size: 16px;
padding-bottom: 8px;
padding-top: 8px;
}

@media screen and (max-width: 767px) {
   .carousel.slide .d-block.w-100{height: 60vh;object-fit: cover;}
   .carousel-caption h3{font-size: 26px;}
   .carousel-caption p{font-size: 18px;}
   .theme-btn {
    padding: 8px;
    font-size: 15px;
    text-transform: capitalize;
  }
  .navbar-nav li a {
    color: #242424;
    font-weight: 500;
}
  .block-title__title{font-size: 30px;}
  .course-one__top-title{padding: 21px 0;}
  .form_register{padding: 60px 0px ; margin-top: 40px;}
  .categories-top{padding: 40px 0px 0px;}
  .section-title__title{font-size: 28px;}
  h2.heading_tea{font-size: 22px;}
  .teacher_list {
    padding: 40px 0px;}
    .footer-widget {
      padding-top: 20px;
      padding-bottom: 20px;
  }
  .site-footer__bottom .container{display: inline-block;}
  .value-content h2{font-size: 36px;}
  .value-content p {
    font-size: 16px;}
  .out_count_{padding: 40px 0px 20px}
  .site-footer__copy{display: inline-block;}
}

.loader_block {
  position: fixed;
  display: block;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%!important;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader_block span {
  left: 50%;
  top: 50%;
  position: relative;
}

.hide-display{

  display:none;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #47ccde ;
  border-color: #47ccde;
  outline: none ;
  
}
.pagination > li > a, .pagination > li > span{
  color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: 2px
}
.pagination {
  margin: 48px auto 40px;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}
.pagination > .active > a{
  background-color: #47ccde ;
  border-color: #47ccde ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #adb5bd;
  padding: 8px 15px;
  outline: none;
  cursor: pointer;
  margin: 3px;
  text-transform: uppercase;
  border-radius: 5px;
}