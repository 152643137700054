.breadcrumb-item+.breadcrumb-item::before{
    content: ">";
}
.video_event iframe{width: 100%;min-height: 500px;}
.bottom_answer_review {
    background-color: #eeeeee;
    padding: 2rem 0px;
}.mh-68 {
    min-height: 68px !important;
}
.radius-50 {
    border-radius: 50px !important;
}
p.line-normal {
    line-height: normal;
    margin-bottom: 0;
}
.btn-update-review {
    background-color: #272925;
    font-weight: 500;
    border-color: #222;
}
.set-mt-2 input {
    margin-top: 11px;
}
.instructors_name h6 {
    color: #222;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 1.2rem;
}