.course__title {
  margin: 2rem 0px;
}
.card_details {
  background-color: #f7f7f7;
  padding: 15px 0px 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
}
tfoot.bg-gray-totoal {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
}
.order-summary__section{margin-top: 14%;}
.form-control{min-height: 50px;}
.course__title h4 {
  color: #0284bc;
}
.product__image{width: 125px !important;}
.product-table {
  margin-top: 0.75em;
  position: relative;
  z-index: 1;
}
small.disabale {
  color: #666;
  font-size: 14px;
}
.product:first-child td {
  padding-top: 0;
  width: 100px;
}
.product__description {
  text-align: left;
  padding-left: 12px;
 
}
.product-thumbnail__wrapper .product-thumbnail__image {
  border-radius: 8px;
  border: 1px rgba(0,0,0,0.1) solid;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-table tr.product td {
  padding: 10px 0px;
}
.total-line__price .order-summary__small-text {
  font-size: 0.8571428571em;
    color: #717171;
}
td.total-line__price .order-summary {
  color: #323232;
  font-weight: 600;
}
.btn-warning {
  background-color: #d4aa32;
  color: #fff;
}
span.payment-due__price {
  color: #323232;
  font-size: 1.7142857143em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em;
}
span.payment__currency {
  color: #717171;
  font-size: 0.8571428571em;
  vertical-align: 0.2em;
  margin-right: 0.5em;
}
td:last-child, th:last-child {
  text-align: right;
}
table.total-line-table tr td, th {
  padding: 5px;
}
th.total-line__name {
  font-weight: 500;
  color: #666;
}
table {
  width: 100%;
}

.order-summary__section {
  position: relative;
  height: 100%;
  padding: 20px;
}
.order-summary__section::before {
  content: "";
  display: block;
  width: 300%;
  position: absolute;
  top: -20px;
  bottom: 0;
  background: #fafafa;
  z-index: -1;
  -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset;
  box-shadow: 0 -1px 0 #e1e1e1 inset;
  left: 0;
  height: 102%;
}


@media screen and (min-width: 1024px){
    .m-ml-2 {
      margin-left: -1rem;
  }
}  