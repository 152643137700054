.list-card {
  border-radius: 0.25rem;
  overflow: hidden;
  background: #fff;
}
.paddingRight-30, .padding-x-30 {
  padding-right: 1.875rem !important;
}
.btn.iconbox {
  padding: 3px !important;
}
.iconbox-sm {
  width: 25px;
  height: 25px;
}
.btn-opacity-facebook {
  background: #3b5998;
  color: #fff;
  line-height: 1;
  border-radius: 20px;
}
.btn-opacity-whatsapp {
  background: #4caf50;
  color: #fff;
  line-height: 1;
  border-radius: 20px;
}
.btn-opacity-linkedin {
  background: #007ab9;
  color: #fff;
  line-height: 1;
  border-radius: 20px;
}
.Certification-img {
  height: 200px;
  object-fit: cover;
  margin-left: 3%;
}
.media .group a {
  font-weight: 600;
  color: #2c3749;
  text-align: left;
  font-size: 18px;
}

.group .list-inline .list-inline-item {
  color: #9c9696;
  font-family: 'Padauk', sans-serif;
}
.group .list-inline .list-inline-item.star i {
  letter-spacing: -2px;
  font-size: 16px;
}
.description_certificate {
  color: #6d6d6d;
  line-height: normal;
  margin-bottom: 5px;
}
.text-gray {
  color: #8f8f93 !important;
}

@media screen and (max-width: 767px) {
  .download_btn {
    display: block;
    width: 100%;
    margin: 10px 0px;
    text-align: center;
  }
}